/* You can add global styles to this file, and also import other style files */

:root {
	--main-color: rgba(0, 0, 0, 0.65);
}

html {
	margin: 0;
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	-webkit-overflow-scrolling: touch;
}

body {
	min-width: 300px;
	min-height: 300px;
	margin: 0 !important;
	padding: 0 !important;
	width: 100vw;
	height: 10vh;
	font-size: 1.0rem;
	font-family: "Helvetica Neue", sans-serif;
	//color: $default-text-color;
	background-color: #fafafa;
}

::-webkit-scrollbar {
	width: 16px;
	height: 16px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb:active {
	background: linear-gradient(to left, #22ADD4, #1E98BA);
}

::-webkit-scrollbar-thumb {
	border-radius: 25px;
	background: #aaaaaa;
	border: 1px solid #f1f1f1;
}

/**
    highlight, hover etc...
 */

.gaia-highlight-black:hover {
	color: black !important;
}

.gaia-highlight-primary:hover {
	color: #1890ff !important;
}

.gaia-highlight-warning:hover {
	color: #fa8c16 !important;
}

.gaia-highlight-danger:hover {
	color: red !important;
}

.color-red {
	color: #f5222d
}

.color-orange {
	color: #fa8c16
}

.color-grey {
	color: #bfbfbf
}

.color-green {
	color: #a0d911
}

.color-blue {
	color: #1890ff;
}

.hover-pointer {
	cursor: pointer;
}

.user-guide {
	h2 {
		font-size: 2.8275em;
		line-height: 1.294117647em;
		margin-top: .8627451em;
		margin-bottom: .43137255em;
	}

	h3 {
		font-size: 2.333em;
		line-height: 1.221428572em;
		margin-top: .78571429em;
		margin-bottom: .43137255em;
	}

	h4 {
		font-size: 1.666em;
		line-height: 1.375em;
		margin-top: .78571429em;
		margin-bottom: .43137255em;
	}

	h5, h6 {
		font-size: 1.4em;
		line-height: 1.2em;
		margin-top: .78571429em;
		margin-bottom: .66em;
	}
}

.amount-text {
	text-align: right;
}

